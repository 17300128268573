/* eslint-disable no-mixed-spaces-and-tabs */
import { H3, Label1, Label2 } from '@pixiepkg/react';
import { Profile } from '@shared/models/Profile.model';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import React, { FC, useRef } from 'react';
import styles from './style/frameExpert.module.scss';
import { ExpertCardV2 } from '@components/ExpertCardV2';

type EventCardProps = {
	experts: Profile[];
	loading?: boolean; // loading pagin experts
	refreshing?: boolean; // loading new batch of experts (when filter changed,...)
	title?: string;
	extensionTop?: React.ReactNode;
	extensionBottom?: React.ReactNode;
	description?: string;
	lastCardRef?: any;
	audit?: boolean;
	center?: boolean;
	onClickExpert?: (id: string) => void;
	onClickExpertBookNow?: (id: string) => void;
	clickedFrom?:
		| 'homepage'
		| 'expert_recommend'
		| 'before_webinar'
		| 'in_webinar'
		| 'after_webinar'
		| 'about';
	backgroundImage?: string;
	backgroundColor?: string;
};

export const FrameExperts: FC<EventCardProps> = React.memo(
	({
		experts,
		loading,
		refreshing = false,
		title,
		description,
		lastCardRef,
		center,
		onClickExpert,
		onClickExpertBookNow,
		clickedFrom,
		extensionTop,
		extensionBottom,
		audit = true,
		backgroundImage,
		backgroundColor
	}) => {
		const containerWidth = useRef<number>(0);
		const containerRef = useRef<HTMLDivElement>(null);

		return (
			<div
				id="find-expert"
				className={classNames(styles.container, { [styles.audit]: audit })}
				style={{ backgroundImage, backgroundColor }}
			>
				{title && (
					<div
						className={classNames(
							'w-full self-start pl-4 pt-8 text-center 2xs:pl-0',
							styles.title
						)}
					>
						{extensionTop && <div className="text-left">{extensionTop}</div>}
						{title && <H3 semiBold>{title}</H3>}
						{description && <Label2 className="font-normal">{description}</Label2>}
						{extensionBottom && (
							<div
								className={classNames(
									'flex justify-center overflow-x-scroll 2xs:justify-start',
									styles.scrollbarHide
								)}
							>
								{extensionBottom}
							</div>
						)}
					</div>
				)}

				{!loading && !refreshing && experts?.length === 0 && (
					<div className="flex w-full flex-col items-center justify-center">
						<Image
							src="/images/empty.svg"
							alt="empty"
							width={270}
							height={270}
							loading="lazy"
						/>
						<div className="mt-8 flex items-center justify-center">
							<Label1 className="ml-4">Chưa có chuyên gia phù hợp.</Label1>
						</div>
					</div>
				)}

				<div
					style={{
						width: containerWidth.current > 0 ? containerWidth.current : '100%'
					}}
					ref={containerRef}
					className={[
						center ? 'justify-center' : 'justify-start',
						styles.gridContainer
					].join(' ')}
					id="p-experts-frame"
				>
					{!refreshing &&
						experts?.length > 0 &&
						experts.map((expert, index) => {
							if (index === experts.length - 1) {
								return (
									<div
										key={`${expert.id}-${index}`}
										id={`${expert.id}-${index}`}
										ref={lastCardRef}
									>
										<ExpertCardV2
											expert={expert}
											onClick={onClickExpert}
											onClickBookNow={onClickExpertBookNow}
											clickedFrom={clickedFrom}
										/>
									</div>
								);
							}
							return (
								<div key={`${expert.id}-${index}`} id={`${expert.id}-${index}`}>
									<ExpertCardV2
										expert={expert}
										onClick={onClickExpert}
										onClickBookNow={onClickExpertBookNow}
										clickedFrom={clickedFrom}
									/>
								</div>
							);
						})}

					{(loading || refreshing) &&
						Array.from({ length: 6 }).map((_, index) => {
							return (
								<div key={`${index}`} id={`${index}`}>
									<ExpertCardV2 loading />
								</div>
							);
						})}
				</div>

				<div className="py-16" />
			</div>
		);
	}
);

FrameExperts.displayName = 'FrameExperts';
