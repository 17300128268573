import React, { useMemo } from 'react';
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import Image from 'next/image';
import 'pure-react-carousel/dist/react-carousel.es.css';
import s from './BannerContentful.module.scss';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { getTooltipProps } from '@shared/constant/tooltip';
import { useSetAtom } from 'jotai';
import { gptChatBox } from '@shared/stores/gptChatBox';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';

interface BannerContentfulSliderProps {
	banners: Banner[];
	width: number;
	height: number;
}

interface Banner {
	bannerUrl: string;
	tooltips?: string;
	doubleClickAction?: () => void;
	type: 'image' | 'video';
}

const BannerContentfulSlider = React.memo(
	({ banners, width, height }: BannerContentfulSliderProps) => {
		return (
			<CarouselProvider
				naturalSlideWidth={width}
				naturalSlideHeight={height}
				totalSlides={banners.length}
				isPlaying
				infinite
			>
				<Slider className={s.slider}>
					{banners.map(
						({ bannerUrl, type, tooltips, doubleClickAction }, index: number) => {
							return (
								<Slide index={index} key={`p-banner-${index}`}>
									<div
										onDoubleClick={doubleClickAction}
										className={s.imageWrapper}
										{...(tooltips ? getTooltipProps(tooltips) : {})}
									>
										{type === 'image' ? (
											<Image
												src={
													bannerUrl ??
													'/images/home/bannerV4/background.svg'
												}
												alt="banner"
												layout="fill"
												objectFit="cover"
												quality={100}
												priority
											/>
										) : (
											<video
												autoPlay
												muted
												loop
												controls={false}
												playsInline={true}
												className={'h-full w-full'}
											>
												<source src={bannerUrl} type="video/mp4" />
											</video>
										)}
									</div>
								</Slide>
							);
						}
					)}
				</Slider>
				<DotGroup className={s.dots} />
			</CarouselProvider>
		);
	}
);
BannerContentfulSlider.displayName = 'BannerContentfulSlider';

export const BannerContentful = React.memo(() => {
	const [, onCopy] = useCopyToClipboard();
	const router = useRouter();
	const setChatGptModel = useSetAtom(gptChatBox);
	const { width } = useWindowSize();

	const desktopBannerList: Banner[] = useMemo(
		() => [
			{
				bannerUrl:
					'https://images.ctfassets.net/p3tbltdaioao/1gWcX6QgOPPa7PjgOZARcM/c4fe35a1d72396f0624379cff0e06b62/banner2.webp',
				doubleClickAction: () => {
					onCopy('NewUser');
					toast.success('Đã copy mã');
				},
				tooltips: 'Double click để copy mã',
				type: 'image'
			},
			{
				bannerUrl:
					'https://images.ctfassets.net/p3tbltdaioao/5uklrjNJQspxOqUk3JFprb/c9a8991257b2b32e392403c6a9d670fb/banner1.webp',
				type: 'image'
			},
			{
				bannerUrl:
					'https://videos.ctfassets.net/p3tbltdaioao/3MlCOKMt0Z6GKGicISBqv6/b15634a0895a065cc24c1b5db9c5117c/bannerVideo.mp4',
				doubleClickAction: () => {
					setChatGptModel({ isOpen: true });
				},
				tooltips: 'Double để thử!!',
				type: 'video'
			},
			{
				bannerUrl:
					'https://images.ctfassets.net/p3tbltdaioao/2MvqFb05d1eR1geojWkDZm/e8b3bc67735550e11a0ad215c666255c/banner3.webp',
				doubleClickAction: () =>
					router.push('/experts/86688c16-91c9-4f91-83f4-c9e84b0c224c'),
				tooltips: 'Double click để xem hồ sơ chuyên gia Quỳnh Nguyễn',
				type: 'image'
			}
		],
		[onCopy, router, setChatGptModel]
	);

	const mobileBannerList: Banner[] = useMemo(
		() => [
			{
				bannerUrl:
					'https://images.ctfassets.net/p3tbltdaioao/5rIauFR3s7FxovwO5URhKV/c10be752fef8f69892e961a216223f0a/mobile2.webp',
				doubleClickAction: () => {
					onCopy('NewUser');
					toast.success('Đã copy mã');
				},
				tooltips: 'Double click để copy mã',
				type: 'image'
			},
			{
				bannerUrl:
					'https://images.ctfassets.net/p3tbltdaioao/4401bN4Jau9h9SbqbbZUWY/096f72fce828fe8877b01a2d962e03a2/mobile1.png',
				type: 'image'
			},
			{
				bannerUrl:
					'https://videos.ctfassets.net/p3tbltdaioao/4hjSVAKqVDDxtdzFGcSGbN/4d2b69da880f0b98f2496697ea39356c/videoGptMobile.mp4',
				doubleClickAction: () => {
					setChatGptModel({ isOpen: true });
				},
				tooltips: 'Double để thử!!',
				type: 'video'
			},
			{
				bannerUrl:
					'https://images.ctfassets.net/p3tbltdaioao/4ZPR8D4rVzSKZIRFjbkGb9/b487fe54b5dcdda5632387d1e59d415e/mobile3.webp',
				doubleClickAction: () =>
					router.push('/experts/86688c16-91c9-4f91-83f4-c9e84b0c224c'),
				tooltips: 'Double click để xem hồ sơ chuyên gia Quỳnh Nguyễn',
				type: 'image'
			}
		],
		[onCopy, router, setChatGptModel]
	);

	const bannerConfig = {
		desktop: {
			width: 100,
			height: 38,
			banners: desktopBannerList
		},
		mobile: {
			width: 40,
			height: 70,
			banners: mobileBannerList
		}
	};

	const BannerContentfulSliderDesktop = () => {
		return <BannerContentfulSlider {...bannerConfig.desktop} />;
	};

	const BannerContentfulSliderMobile = () => {
		return <BannerContentfulSlider {...bannerConfig.mobile} />;
	};

	return (
		<div className="min-h-[600px] lg:min-h-[480px]">
			{width > SCREEN_SIZES.xs ? (
				<BannerContentfulSliderDesktop />
			) : (
				<BannerContentfulSliderMobile />
			)}
		</div>
	);
});

BannerContentful.displayName = 'BannerContentful';
