import { NonSSRWrapper } from '@components/NoSSRWrapper';
import React, { useEffect } from 'react';

/**
 *
 */
export function init() {
	const chatbox = document.getElementById('fb-customer-chat');
	chatbox?.setAttribute('attribution', 'biz_inbox');
	chatbox?.setAttribute('page_id', '102833651789259');
	chatbox?.setAttribute('theme_color', '#444cff');
	chatbox?.setAttribute('minimized', 'false');
	chatbox?.setAttribute('logged_in_greeting', 'Pixie có thể support cho bạn nè?');
	chatbox?.setAttribute('greeting_dialog_display', 'show');
	chatbox?.setAttribute('guest_chat_mode', 'disabled');

	const _window = window as any;
	_window.fbAsyncInit = function () {
		FB?.init({
			appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
			xfbml: true,
			version: 'v12.0'
		});
	};

	(function (d, s, id) {
		let js: any,
			// eslint-disable-next-line prefer-const
			fjs: any = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		// eslint-disable-next-line prefer-const
		js = d.createElement(s);
		js.id = id;
		js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
		fjs.parentNode.insertBefore(js, fjs);
	})(document, 'script', 'facebook-jssdk');

	(function (d, s, id) {
		let js: any,
			// eslint-disable-next-line prefer-const
			fjs: any = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		// eslint-disable-next-line prefer-const
		js = d.createElement(s);
		js.id = id;
		js.src = '//connect.facebook.net/en_US/messenger.Extensions.js';
		fjs.parentNode.insertBefore(js, fjs);
	})(document, 'script', 'Messenger');
}

/**
 *
 */
export function cleanup() {
	(function (d, id) {
		const target: any = d.getElementById(id);
		if (target) {
			target.parentNode.removeChild(target);
		}
	})(document, 'facebook-jssdk');

	// @ts-expect-error: window delete
	delete window.FB;
}

export function FacebookMess() {
	useEffect(() => {
		init();
		const myTimeout = setTimeout(() => {
			if (window.FB) {
				const _FB: any = window.FB;
				_FB?.CustomerChat?.show(true);
			}
		}, 5000);

		return () => {
			cleanup();
			clearTimeout(myTimeout);
		};
	}, []);

	return (
		<NonSSRWrapper>
			<div>
				<div id="fb-customer-chat" className="fb-customerchat"></div>
			</div>
		</NonSSRWrapper>
	);
}
