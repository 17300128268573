import { WebinarCollections } from '@shared/models/Webinar.model';

export const getExpertHostsByCollection = (collection?: WebinarCollections) => {
	if (
		!collection ||
		!collection.webinars ||
		!collection.webinars.length ||
		!collection.webinars[0].hosts ||
		!Object.keys(collection.webinars[0].hosts).length
	) {
		return undefined;
	}

	return Object.values(collection.webinars[0].hosts) ?? [];
};
