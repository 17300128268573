import { Button, KindButton } from '@pixiepkg/react';
import { FC, useState } from 'react';
type Props = Partial<{
	onFilter: () => string[];
	defaultParams: string[];
	categories: string[];
	onChangeCategory: (cate: string) => void;
}>;

interface TagProps {
	children: React.ReactNode;
	onClick: (tag: string) => void;
	active?: boolean;
}

const Tag = ({ children, onClick, active }: TagProps) => {
	return (
		<Button
			style={{ minWidth: 'fit-content', width: 'max-content' }}
			onClick={() => onClick(children as string)}
			className={` ${!active ? 'border-1 border-solid border-gray-300 bg-white' : 'w-max'} `}
			kind={active ? KindButton.BRAND : KindButton.GHOST}
			rounded
		>
			{children}
		</Button>
	);
};

const Category: FC<Props> = ({ categories, onChangeCategory }) => {
	const [activeTag, setActiveTag] = useState('');

	return (
		<div className="mt-8 flex flex-wrap justify-center gap-4 2xs:flex-nowrap 2xs:justify-start">
			{categories?.map((tag, index) => (
				<Tag
					key={index}
					onClick={(cate) => {
						if (cate === activeTag) {
							onChangeCategory && onChangeCategory('');
							setActiveTag('');
						} else {
							onChangeCategory && onChangeCategory(cate);
							setActiveTag(cate);
						}
					}}
					active={activeTag === tag}
				>
					{tag}
				</Tag>
			))}
		</div>
	);
};

export default Category;
