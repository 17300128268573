import { Transition } from '@headlessui/react';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import s from '@components/Animations/AnimationUp/styles/animationup.module.scss';

interface Props {
	children?: React.ReactNode;
	hiddenOverflow?: boolean;
	animationClassName?: string;
}

export const AnimationUp = ({ children, hiddenOverflow, animationClassName }: Props) => {
	const [show, setShow] = useState(false); // fix production not run animation
	useEffect(() => {
		setShow(true);
	}, []);
	return (
		<div className={classnames(hiddenOverflow && s.hiddenOverflow)}>
			<Transition
				show={show}
				enter={classnames(animationClassName, 'transform transition duration-300')}
				enterFrom="opacity-0 translate-y-12"
				enterTo="opacity-100 translate-y-0"
				className="overflow-hidden"
			>
				{children}
			</Transition>
		</div>
	);
};
