import { Body1, Body2, Body3, Button, H2, H3, H4, H5, SizeButton } from '@pixiepkg/react';
import { SCREEN_SIZES, useWindowSize } from '@shared/hooks/useWindownSize';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import PictureLagre from './images/mask.png';
import styles from './style/frameExpert.module.scss';

export const BecomeExpert = () => {
	const { width } = useWindowSize();
	const { t } = useTranslation('home');
	const Title = width > SCREEN_SIZES.xs ? H2 : H4;
	const Body = width > SCREEN_SIZES.xs ? Body1 : Body2;
	const SubTitle = width > SCREEN_SIZES.xs ? H3 : H5;
	const router = useRouter();

	const handleBecomeExpert = () => {
		router.push('/become-an-expert');
	};

	return (
		<div className="mx-5 flex justify-center pb-32 pt-24 xs:pb-16 xs:pt-12">
			<div className={styles.container}>
				<div className="md:hidden" style={{ maxWidth: '600px' }}>
					<Title bold>{t('becomeExpert.title')}</Title>
					<Body>{t('becomeExpert.description')}</Body>
					<Button
						className="mt-9"
						size={width > SCREEN_SIZES.xs ? SizeButton.XLARGE : SizeButton.LARGE}
						rounded
						onClick={handleBecomeExpert}
					>
						{t('becomeExpert.button')}
					</Button>
				</div>

				<div
					className={['hidden md:block', styles.contentSmall].join(' ')}
					style={{ maxWidth: '300px' }}
				>
					<SubTitle bold>{t('becomeExpert.title')}</SubTitle>
					<Body3>{t('becomeExpert.description')}</Body3>
					<Button
						className={'mt-6'}
						size={width > SCREEN_SIZES.xs ? SizeButton.XLARGE : SizeButton.LARGE}
						rounded
						onClick={handleBecomeExpert}
					>
						{t('becomeExpert.button')}
					</Button>
				</div>

				<div className={styles.image}>
					<Image src={PictureLagre} alt="picture" loading="lazy" />
				</div>
			</div>
		</div>
	);
};
