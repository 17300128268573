import { H3 } from '@pixiepkg/react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Reviewer } from '../Reviewer';
import s from './Testimonial.module.scss';

export const Testimonial = () => {
	const { t } = useTranslation('home');
	return (
		<div className="bg-gray-50 pb-8 pt-0">
			<div className="mx-auto px-20 py-10 pt-20 text-center xs:px-16">
				<H3 className="relative inline-block" bold>
					{t('reviewr.title')}
					<span className={classNames(s.icon, s.icon1)}>😍</span>
					<span className={classNames(s.icon, s.icon2)}>️️👌</span>
					<span className={classNames(s.icon, s.icon3)}>️️😊</span>
				</H3>
			</div>
			{/* <TopReviewer /> */}
			<Reviewer />
		</div>
	);
};
