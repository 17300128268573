import React, { useEffect, useState } from 'react';
import { api } from '@api';
import { useAtom } from 'jotai';
import { sessionAtom } from '@shared/stores/session.store';
import { Schedule } from '@shared/models/Schedule.model';
import s from './NextSchedule.module.scss';
import calendarIcon from './calendar-color.svg';
import Image from 'next/legacy/image';
import { Button, H4, H6, KindButton, Label2, Label3, Label4 } from '@pixiepkg/react';
import { CalendarOutline } from '@pixiepkg/icon';
import { useTranslation } from 'next-i18next';
import { getFullName } from '@shared/utils/name';
import router from 'next/router';
import nProgress from 'nprogress';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getFullDate } from '@shared/utils/date';
import { AnimationUp } from '@components/Animations/AnimationUp';
import { useSendEvent } from '@shared/hooks/useSendEvent';

export const NextSchedule = ({ page }: { page: string }) => {
	const [session] = useAtom(sessionAtom);
	const [nextSchedule, setNextSchedule] = useState<Schedule>();
	const { t } = useTranslation();
	const [status, setStatus] = useState<'late' | 'callable' | 'early' | 'loading'>('loading');
	const [joinLable, setJoinLable] = useState('');
	const { sendEventGAAndBackend } = useSendEvent();

	const goToPayment = async () => {
		if (nextSchedule) {
			nProgress.start();
			try {
				const languages = navigator.languages as string[];
				const locale = languages.find((ele) => ele.toLowerCase() === 'vi') ? 'vn' : 'en';
				const payType =
					nextSchedule.type === 'p2p'
						? 'p2p'
						: nextSchedule.bookingID === nextSchedule.resourceID
						? 'webinar'
						: 'webinar_collection';

				const payRes = await api.getPaymentURL({
					resource_id: nextSchedule.resourceID,
					locale: locale,
					type: payType,
					return_url:
						window.location.hostname === 'localhost'
							? `http://localhost:3000/payment/${nextSchedule.bookingID}/status`
							: `https://${window.location.hostname}/payment/${nextSchedule.bookingID}/status`
				});
				const link = payRes.data;
				const newWindow = window.open(link);
				if (newWindow) newWindow.opener = null;
			} catch (error) {
				toast.error('Can not go to payment page!');
			}

			nProgress.done();
		}
	};

	const goToRoom = () => {
		if (nextSchedule && window) {
			sendEventGAAndBackend('click_reminder_join_now', {
				meeting_id: nextSchedule.bookingID,
				type: nextSchedule.type
			});
			window.localStorage.setItem('schedule', JSON.stringify(nextSchedule));
			router.push(`/meeting`);
		}
	};

	useEffect(() => {
		if (session.isSignedIn) {
			api.getMySchedule({ tab_query: 'upcoming', limit: 1 }).then((res) => {
				const schedules = res.data || [];
				let _nextSchedule = undefined;
				let nextScheduleTime;
				for (const _schedule of schedules) {
					if (
						!nextScheduleTime ||
						dayjs.unix(_schedule.start).isAfter(nextScheduleTime)
					) {
						_nextSchedule = _schedule;
						nextScheduleTime = _schedule.start;
					}
				}
				setNextSchedule(_nextSchedule);
			});
		} else {
			setNextSchedule(undefined);
		}
	}, [session.isSignedIn]);

	useEffect(() => {
		setStatus('loading');
		let interval: any;

		if (nextSchedule?.start && nextSchedule.end) {
			let startDate = dayjs.unix(nextSchedule.start);

			if (nextSchedule.type === 'webinar') {
				startDate = startDate.subtract(15, 'minute');
			}
			const countDownDate = startDate.toDate().getTime();

			const endDate = dayjs.unix(nextSchedule.end).toDate().getTime();

			// Update the count down every 1 second
			interval = setInterval(function () {
				// Get today's date and time
				const now = new Date().getTime();

				// Find the distance between now and the count down date
				const distance = countDownDate - now;

				// If the count down is finished, write some text
				if (distance < 0) {
					clearInterval(interval);

					if (endDate - now >= 0) {
						setStatus('callable');
						setJoinLable('');
					} else {
						setStatus('late');
						setJoinLable('Ended');
					}
				} else {
					const durations = [];
					// Time calculations for days, hours, minutes and seconds
					const days = Math.floor(distance / (1000 * 60 * 60 * 24));
					if (days !== 0) {
						durations.push(`${days} d`);
					}
					const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					if (hours !== 0) {
						durations.push(`${hours} h`);
					}
					const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
					if (minutes !== 0) {
						durations.push(`${minutes} m`);
					}
					const seconds = Math.floor((distance % (1000 * 60)) / 1000);
					if (seconds !== 0) {
						durations.push(`${seconds} s`);
					}

					setStatus('early');
					setJoinLable([...durations, 'left'].join(' '));
				}
			}, 1000);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [nextSchedule]);

	if (!nextSchedule) {
		return null;
	}

	const { participants } = nextSchedule;

	const partnerId = participants
		? Object.keys(participants).find((accountID) =>
				session.user?.id
					? accountID != session.user.id
					: nextSchedule.participants[accountID].role === 'host'
		  )
		: undefined;
	const partnerFullName = partnerId
		? getFullName(participants[partnerId].firstName, participants[partnerId].lastName)
		: '';
	const partnerAvatar = partnerId
		? participants[partnerId].avatar
		: 'https://dummyimage.com/64x64';
	const partner = partnerId ? participants[partnerId] : null;

	return (
		<AnimationUp>
			<section className="flex justify-center">
				<div className={s.container}>
					<div className={s.leftContainer}>
						<div className="flex">
							<Image
								alt="calendarIcon"
								src={calendarIcon}
								width={56}
								height={56}
								loading="lazy"
							/>
							<H4 semiBold className="ml-8 text-black">
								Lịch hẹn sắp tới <br />
								của {session.user?.firstName}
							</H4>
						</div>
						<Label2 className="mt-4 font-normal text-gray-500">
							Chuẩn bị sổ tay, giấy bút để lưu lại những kiến thức bổ ích từ chuyên
							gia bạn nhé
						</Label2>

						<Button
							leftIcon={<CalendarOutline size={18} />}
							kind={KindButton.SECONDARY}
							className="mt-4"
							onClick={() => {
								sendEventGAAndBackend('click_reminder_my_booking', {
									meeting_id: nextSchedule.bookingID,
									type: nextSchedule.type
								});
								sendEventGAAndBackend('view_schedule', {
									from: page
								});
								router.push('/dashboard/p2ps');
							}}
						>
							{t('nav.bookings')}
						</Button>
					</div>
					<div className={s.rightContainer}>
						<div className="flex w-full items-center justify-between sm:flex-col">
							<div className="flex items-center">
								<div className="self-center rounded bg-brand-500 px-2 py-1">
									<Label4 className="text-white">P2P</Label4>
								</div>
								<Label3 className="ml-2">
									{`Diễn ra vào ${
										nextSchedule?.start
											? getFullDate(
													dayjs.unix(nextSchedule.start).toDate(),
													router.locale
											  )
											: ''
									}`}
								</Label3>
							</div>
							<Label3 className="w-52 text-right text-brand-500">{joinLable}</Label3>
						</div>
						<div className="flex items-center justify-between sm:flex-col">
							<div className="mt-4 flex items-center">
								<div>
									<Image
										alt="calendarIcon"
										className="rounded-full"
										src={partnerAvatar}
										width={44}
										height={44}
										loading="lazy"
									/>
								</div>
								<div className="ml-4 ">
									<H6 semiBold>{partnerFullName}</H6>
									<Label3 className="-mt-1 uppercase text-gray-500">
										{partner?.role}
									</Label3>
								</div>
							</div>
							{nextSchedule?.status === 'paid' ? (
								<Button rounded onClick={goToRoom} disabled={status !== 'callable'}>
									Tham gia ngay
								</Button>
							) : (
								<Button rounded onClick={goToPayment}>
									Go to payment
								</Button>
							)}
						</div>
						<div className={s.border} />
						<div className="flex items-center">
							<div className={s.borderLeft} />
							<Label2 className={s.topic}>{`[Topic] ${nextSchedule.topic}`}</Label2>
						</div>
					</div>
				</div>
			</section>
		</AnimationUp>
	);
};
