import { useEffect, useState } from 'react';

export const useIsMobileAgent = (defaultV?: boolean) => {
	const [isMobileAgent, setIsMobileAgent] = useState(defaultV ?? false);
	useEffect(() => {
		if (typeof window === 'undefined') return;
		const userAgent = navigator.userAgent;
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			userAgent
		);
		setIsMobileAgent(isMobile);
	}, []);

	return isMobileAgent;
};
