import React from 'react';
import { Template } from '@components/Template';
import { withSession } from '@shared/hocs/withSession';
import type { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Home } from '@containers/Home';
import { api } from '@api';
import { ExpertsByDomain } from '@shared/models/ProfileV2.model';

interface HomePageProps {
	profilesV2: ExpertsByDomain[];
	categories: string[];
}

const HomePage = ({ profilesV2, categories }: HomePageProps) => {
	return (
		<>
			<Template showFooter showNavigationLinks fixedNavBar>
				<Home profilesV2={profilesV2 ?? []} categories={categories} />
			</Template>
		</>
	);
};

export const getServerSideProps = async ({ locale, req }: GetServerSidePropsContext) => {
	const _cookieLocal = req.cookies['NEXT_LOCALE'];
	if (_cookieLocal && _cookieLocal !== locale) {
		return {
			redirect: {
				permanent: true,
				destination: `/${_cookieLocal}/${req.url}`,
				locale: _cookieLocal
			}
		};
	}

	const profilesV2 = await (await api.getExpertsV2()).data;

	const categories = (() => {
		if (locale === 'vi') {
			return profilesV2
				.map((p) => p.domainVN)
				.filter((cate) => cate !== '' && cate !== 'personalized');
		}

		return profilesV2
			.map((p) => p.domain)
			.filter((cate) => cate !== '' && cate !== 'personalized');
	})();

	return {
		props: {
			profilesV2,
			categories,
			...(await serverSideTranslations(locale || 'en', ['common', 'home']))
		}
	};
};

export default withSession()(HomePage);
