import { Avatar, Body3, Label2, SizeAvatar } from '@pixiepkg/react';
import React from 'react';
import styles from './styles/TestimonialCard.module.scss';
import Link from 'next/link';
import classNames from 'classnames';
import { Expert } from '@shared/models/Profile.model';

type expertData = Expert & {
	name: string;
	field: string;
	avatar?: string;
	id: string;
};

export type TestimonialProps = {
	content: string;
	author: string;
	headline?: string;
	expert: expertData;
	avatar: string;
	topic: string;
};

const Internal: React.ForwardRefRenderFunction<HTMLDivElement, TestimonialProps> = (props, ref) => {
	return (
		<div ref={ref} className={styles.container}>
			{/* Info expert and user */}
			<div>
				<div className={styles.header}>
					<div className="flex items-center justify-between">
						<div className="flex">
							<div className="mr-2 flex-shrink-0">
								<Avatar
									imageProps={{
										style: {
											width: '40px',
											height: '40px',
											objectFit: 'cover'
										}
									}}
									circle
									size={SizeAvatar.SMALL}
									src={
										props.expert.avatar ||
										'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
									}
								/>
							</div>
							<div>
								<div className="flex h-[20px] items-center gap-1.5">
									<div className="hover min-w-[54px] rounded-full bg-gray-100 pb-0.5 pb-1 pl-3.5 pr-3 pt-px text-center text-sm font-medium text-green xl:text-lg 2xs:text-base">
										Expert
									</div>
									<Label2 className={styles.lineClamp}>
										{props.expert.name}
									</Label2>
								</div>
								<p className="px-1 text-sm font-semibold text-black opacity-50 xl:text-lg 2xs:text-base">
									{props.expert.field}
								</p>
							</div>
						</div>
						<div className=" h-[40px] pb-2.5">
							<Link href={`/experts/${props.expert.id}`} passHref legacyBehavior>
								<p
									className={classNames(
										'cursor-pointer text-sm font-semibold text-brand-600 xl:text-lg 2xs:text-base ',
										styles.lineClamp
									)}
								>
									Trang cá nhân
								</p>
							</Link>
						</div>
					</div>

					<div className="flex items-center">
						<div className="mr-2 flex-shrink-0">
							<Avatar
								imageProps={{
									style: {
										width: '40px',
										height: '40px',
										objectFit: 'cover'
									}
								}}
								circle
								size={SizeAvatar.SMALL}
								src={
									props.avatar ||
									'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
								}
							/>
						</div>
						<div>
							<div className="flex h-[20px] gap-2.5 px-2">
								<Label2>{props.author}</Label2>
							</div>
							<p className="px-2 text-sm font-semibold text-black opacity-50 xl:text-lg 2xs:text-base">
								Learner
							</p>
						</div>
					</div>
				</div>

				<div className="mb-2 mt-5">
					<Body3 semiBold className={styles.lineClamp5}>
						&quot;{props.content}&quot;
					</Body3>
				</div>
			</div>

			<div className="flex gap-2">
				<div className="hover min-h-[32px] min-w-[171px] rounded-full bg-gray-300 bg-opacity-20 px-5 py-2 text-center text-sm font-medium text-gray-500 xl:text-lg 3xs:hidden">
					Topic : {props.topic}
				</div>
			</div>
		</div>
	);
};

export const TestimonialCard = React.forwardRef<HTMLDivElement, TestimonialProps>(Internal);
