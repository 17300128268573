import { Button, H1, KindButton, Label1, SizeButton } from '@pixiepkg/react';
import React from 'react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { WebinarCollections } from '@shared/models/Webinar.model';
import { getExpertHostsByCollection } from '@shared/utils/collections';
import { getFullName } from '@shared/utils/name';
import { getShortDateNumeric } from '@shared/utils/date';
import dayjs from 'dayjs';
import { putCommaMoney } from '@shared/utils/money';
import { useImageBlurProps } from '@shared/hooks/useImageBlurProps';

interface Props {
	collection: WebinarCollections;
}

export const CollectionView = React.memo(({ collection }: Props) => {
	const blurProps = useImageBlurProps();
	const router = useRouter();
	const bannerURL =
		collection.image && collection.image.length > 0
			? collection.image
			: '/images/expert-profile/default-desktop-banner.png';

	const hosts = getExpertHostsByCollection(collection);
	const hostName =
		hosts && hosts?.length > 0 ? getFullName(hosts[0].firstName, hosts[0].lastName) : '';
	const dates =
		collection.webinars && collection.webinars.length > 0
			? collection.webinars
					.map((wb) => {
						return getShortDateNumeric(dayjs.unix(wb.start).toDate());
					})
					.join(', ')
			: '';

	return (
		<div className="mx-4 mt-6 flex justify-center md:flex-col xs:mt-4">
			<div className={'mx-auto min-h-[100px] max-w-7xl flex-1'}>
				<Image
					alt="banner-collection"
					className="cursor-pointer rounded-lg"
					src={bannerURL}
					width={650}
					height={330}
					objectFit="cover"
					onClick={() => router.push(`/collections/${collection.id}`)}
					loading="lazy"
					{...blurProps}
				/>
			</div>
			<div className="-mt-4 ml-8 flex flex-1 flex-col justify-center md:mt-4">
				<H1 semiBold>{collection.name}</H1>
				<div className="flex w-full space-x-8 border-b border-solid border-gray-300">
					<div className="w-40">
						<Label1 className="font-normal">Chuyên gia:</Label1>
						<Label1 semiBold>{hostName}</Label1>
					</div>
					<div>
						<Label1 className="font-normal">
							{`${collection.webinars ? collection.webinars.length : '0'} buổi`}
						</Label1>
						<Label1 semiBold>{dates}</Label1>
					</div>
				</div>
				<div className="mt-4 flex w-full items-center space-x-16">
					<div>
						<Label1 className="font-normal">Học phí:</Label1>
						<Label1 semiBold>{`${putCommaMoney(collection.cost)}`}</Label1>
					</div>
					<div>
						<Button
							size={SizeButton.LARGE}
							kind={KindButton.BRAND}
							onClick={() => router.push(`/collections/${collection.id}`)}
						>
							Đăng ký ngay
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
});

CollectionView.displayName = 'CollectionView';
